import type { FC } from 'react';
import React, { useContext, useCallback } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { Settings } from '@atlaskit/atlassian-navigation';

import { SSRMouseEventWrapper, SSR_NAV_SETTINGS_METRIC } from '@confluence/browser-metrics';
import { RoutesContext } from '@confluence/route-manager/entry-points/RoutesContext';
import { ADMIN_SETTINGS, ADMIN_CENTER } from '@confluence/named-routes';
import { START_TOUCH } from '@confluence/navdex';
import { markErrorAsHandled } from '@confluence/graphql';
import { ErrorDisplay, isUnauthorizedError } from '@confluence/error-boundary';
import { useSessionData } from '@confluence/session-data';
import { ConfluenceEdition } from '@confluence/change-edition/entry-points/ConfluenceEdition';
import { OperationsQuery } from '@confluence/site-operations';
import type { OperationsQueryType } from '@confluence/site-operations';

import { EngagementInlineDialogLoaderNav3 } from '../EngagementInlineDialogLoader';

const i18n = defineMessages({
	settingsName: {
		id: 'app-navigation.settings.name',
		defaultMessage: 'Confluence administration',
	},
});

type SettingsComponentProps = {};

export const SettingsComponent: FC<SettingsComponentProps> = () => {
	const { edition, userId } = useSessionData();
	const { match, location } = useContext(RoutesContext);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isPremiumOnAdminCenterPage =
		edition === ConfluenceEdition.PREMIUM && location?.pathname === ADMIN_CENTER.toUrl();

	const { data, loading, error } = useQuery<OperationsQueryType>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		OperationsQuery,
		{
			skip: !userId,
		},
	);

	const fireAnalytics = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: 'globalNavigation',
				action: 'clicked',
				actionSubject: 'navigationItem',
				actionSubjectId: 'settings',
				attributes: {
					selectedItemPageContext: match ? match.name : undefined,
					navigationLayer: 'global',
					navVersion: '3',
					navdexPointType: START_TOUCH,
				},
			},
		}).fire();
	}, [createAnalyticsEvent, match]);

	const handleOnClick = useCallback(() => {
		fireAnalytics();
	}, [fireAnalytics]);

	let isSelected;

	if (typeof document !== 'undefined') {
		isSelected =
			!!document.querySelector("meta[name='ajs-is-admin-page']") || isPremiumOnAdminCenterPage;
	}

	//ts fail on AK side
	const AkSettings = Settings as any;

	if (loading) {
		return null;
	}

	if (error) {
		if (isUnauthorizedError(error)) {
			markErrorAsHandled(error);
			return null;
		}
		return <ErrorDisplay error={error as Error} />;
	}

	const isAdmin = data?.siteOperations?.application?.includes('administer');

	return isAdmin ? (
		<EngagementInlineDialogLoaderNav3
			engagementId="adminSettingsIcon"
			dataVC="app-navigation-settings-engagement-inline-dialog"
		>
			<span data-testid="app-navigation-settings">
				<SSRMouseEventWrapper metricName={SSR_NAV_SETTINGS_METRIC}>
					<AkSettings
						onClick={handleOnClick}
						href={!isPremiumOnAdminCenterPage ? ADMIN_SETTINGS.toUrl() : '#'}
						name="settings"
						tooltip={<FormattedMessage {...i18n.settingsName} />}
						isSelected={isSelected}
					/>
				</SSRMouseEventWrapper>
			</span>
		</EngagementInlineDialogLoaderNav3>
	) : null;
};
